import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import css from "../style/singlepage.module.scss"
import Doc from "../../../components/Doc"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Lottie from "react-lottie"
import * as animationData from "../../../images/lottie/3sBlockLogo.json"
import ExhibitorList from "../../../components/ExhibitorList"

const _lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  className: `ascrg`,
}
const IndexPage = data => {

  return (
    <Layout mode={`about`} lang={`ja`}>
      <SEO title={`TRANS BOOKS DOWNLOADs`} lang={`ja`} />
      <div className={css.wrap}>
        <div className={css.flexSticky}>
          <div className={css.flexSticky__left}>
            <div className={css.flexSticky__left__heading}>
              <div className={`${css.logoBox} fixedRatioBox is-2x1 border`}>
                <Lottie options={_lottieOptions} width={300} height={150} />
              </div>
            </div>
          </div>
          <div className={css.flexSticky__right}>
            <Doc>
              <h3 style={{ marginTop: 0 }}>ご挨拶</h3>
              <p>オンライン書店 TRANS BOOKS DOWNLOADs へようこそ。</p>

              <p>
                「TRANS
                BOOKS」は、電子も非電子もそれ以外もメディアを問わず、どんな本でも販売するブックフェアとして、2017年から開催しています。これまで毎年秋にに開催し、数々の多彩な書籍を販売してきました。
              </p>

              <p>
                《本》は人と情報の橋渡しし、時代とともに形をかえて、広がり続けるものです。このような時代性を表す《本》だからこそできる表現や、コミュニケーションをもっと探ってみたいと思い、始めたのが「TRANS
                BOOKS」です。
              </p>

              <p>
                私たちは《本》を表現を懐深く受け入れるプラットフォームであると考え、本や本を取り巻く体験の多様性、可能性や面白さを、楽しみながらふれてもらう場を目指し、活動を続けてきました。
              </p>

              <p>
                COVID-19による影響から、私たちを取り巻く環境は一変しました。それに伴い、本を始めとするメディアと人々の関係も、変わりつつあります。これまで当たり前のようにリアルで見たり触れていたものを、オンラインやデジタルに置き換えるだけではない関係が、これから作り出されて行くのではないでしょうか。
              </p>

              <p>
                運営メンバーで、今ならではのメディアと自分たちの関係についてディスカッションし、導き出されたテーマが「Download」でした。「Download」はネットワーク上にあるデータを、自分のパソコンやスマートフォンへ、そしてオンライン空間から実生活へ転送することです。私たちは「Download」をオンラインと自分たちのパーソナルな世界をつなぐ入り口として捉え、今だからこそ楽しめる、本と本に関わる多様な体験を提供したいと考えています。
              </p>

              <p>
                5月から11月まで開催予定の、 TRANS BOOKS
                DOWNLOADs、「Download」から広がる様々な《本》をぜひお楽しみください。
              </p>
            </Doc>
          </div>
        </div>
        <div className={css.flexSticky}>
          <div className={css.flexSticky__left}>
            <div className={css.flexSticky__left__heading}>
              <div className={`fixedRatioBox is-16x9 border`}>
                <h3 className={css.headingText}>
                  参加作家 <small>(今後も続々参加予定！)</small>
                </h3>
              </div>
            </div>
          </div>
          <div className={css.flexSticky__right}>
            <Doc>
              <div>
                <ExhibitorList lang={`ja`} />
              </div>
            </Doc>
          </div>
        </div>
        <div className={css.flexSticky}>
          <div className={css.flexSticky__left}>
            <div className={css.flexSticky__left__heading}>
              <figure>
                <div className={`fixedRatioBox is-16x9 `}>
                  <img
                    className={`border`}
                    src="/assets/img/yu-illustration.png"
                    alt=""
                  />
                </div>
                <figcaption>Illustration by Yu Yamamoto</figcaption>
              </figure>
            </div>
          </div>
          <div className={css.flexSticky__right}>
            <Doc>
              <h3 style={{ marginTop: 0 }}>TRANS BOOKS運営委員会</h3>
              <p>
                <OutboundLink href={`https://iimio.com/`} target={`_blank`}>
                  飯沢未央
                </OutboundLink>
                （アーティスト）、
                <OutboundLink
                  href={`https://shunyahagiwara.com/`}
                  target={`_blank`}
                >
                  萩原俊矢
                </OutboundLink>
                （Web デザイナー）、
                <OutboundLink
                  href={`https://www.hatayurie.com/`}
                  target={`_blank`}
                >
                  畑ユリエ
                </OutboundLink>
                （グラフィックデザイナー）、
                <OutboundLink
                  href={`https://shunyahagiwara.com/`}
                  target={`_blank`}
                >
                  齋藤あきこ
                </OutboundLink>
                （ブロガー）。電子も非電子もそれ以外も、どんな本でも購入できるメディア何でも書店「TRANS
                BOOKS」を運営している。
              </p>
              <ul>
                <li>
                  <OutboundLink
                    href={`https://transbooks.center/`}
                    target={`_blank`}
                  >
                    TRANS BOOKS Official Website
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href={`https://www.instagram.com/transbooks/`}
                    target={`_blank`}
                  >
                    Instagram
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href={`https://twitter.com/transbookstrans`}
                    target={`_blank`}
                  >
                    Twitter
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href={`https://www.facebook.com/transbookstrans/`}
                    target={`_blank`}
                  >
                    Facebook Page
                  </OutboundLink>
                </li>
              </ul>

              <hr />

              <h3>プライバシーポリシーと特定商取引法に基づく表記</h3>
              <p>
                プライバシーポリシーと特定商取引法に基づく表記についてはそれぞれ以下のページをご参照ください。
              </p>

              <ul>
                <li>
                  <Link to={"/downloads/privacy-policy/"}>
                    プライバシーポリシー
                  </Link>
                </li>
                <li>
                  <Link to={"/downloads/terms-of-use/"}>利用規約</Link>
                </li>
                <li>
                  <Link to={"/downloads/law/"}>特定商取引法に基づく表記</Link>
                </li>
              </ul>

              <hr />
              <h3>連絡先 & お問い合わせ</h3>
              <p>transbooks.info [at-mark] gmail.com</p>
            </Doc>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default prop => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  artistName
                  alphabetical
                  artistTitle
                }
                fields {
                  slug
                  langKey
                }
                id
              }
            }
          }
        }
      `}
      render={data => <IndexPage site={data}>{prop.children}</IndexPage>}
    />
  )
}
